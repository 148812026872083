import provideState_MaX9fQr3GV from "/home/portal/www/frontend/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.js";
import revive_payload_client_4sVQNw7RlN from "/home/portal/www/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/portal/www/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/portal/www/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/portal/www/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/portal/www/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/portal/www/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/portal/www/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/portal/www/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/portal/www/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_W86eSGFqyZ from "/home/portal/www/frontend/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import language_Zy8KLHw9oT from "/home/portal/www/frontend/node_modules/nuxt-language-negotiation/dist/runtime/plugins/language.mjs";
import blokkliEditable_kzWzvLoIBF from "/home/portal/www/frontend/node_modules/blokkli-beta/dist/runtime/plugins/blokkliEditable.mjs";
import plugin_tbFNToZNim from "/home/portal/www/frontend/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import recaptcha_85gNSCNFUU from "/home/portal/www/frontend/plugins/recaptcha.ts";
import clickaway_ZmIhydr7La from "/home/portal/www/frontend/plugins/clickaway.ts";
import graphqlMiddleware_2xI7SMabjg from "/home/portal/www/frontend/plugins/graphqlMiddleware.ts";
import drupalUser_HxAWH89yLt from "/home/portal/www/frontend/plugins/drupalUser.ts";
import texts_P4dMAr3Ct0 from "/home/portal/www/frontend/node_modules/nuxt-easy-texts/dist/runtime/plugins/texts.mjs";
import formkitLanguage_HZSCwbgbEX from "/home/portal/www/frontend/plugins/formkitLanguage.ts";
import oruga_2qeBGjDWKO from "/home/portal/www/frontend/plugins/oruga.ts";
export default [
  provideState_MaX9fQr3GV,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_W86eSGFqyZ,
  language_Zy8KLHw9oT,
  blokkliEditable_kzWzvLoIBF,
  plugin_tbFNToZNim,
  recaptcha_85gNSCNFUU,
  clickaway_ZmIhydr7La,
  graphqlMiddleware_2xI7SMabjg,
  drupalUser_HxAWH89yLt,
  texts_P4dMAr3Ct0,
  formkitLanguage_HZSCwbgbEX,
  oruga_2qeBGjDWKO
]