/**
 * This composable is used to get the hostname of the request.
 * Because we only have a single nuxt instance and a reverse proxy in front of it,
 * we can't rely on the `host` header to get the hostname.
 */
export function useHostName() {
  const url = useRequestURL()
  let host = url.hostname
  const headers = useRequestHeaders(['x-forwarded-host'])

  // Pick the first value of the comma separated string
  // if multiple values are provided.
  if (headers['x-forwarded-host']?.includes(',')) {
    host = headers['x-forwarded-host']?.split(',')[0]
  }

  return host
}
